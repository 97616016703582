import seedrandom from 'seedrandom';
import React, { useEffect, useRef, useState } from 'react';
import './styles/index.css';
import ScrollingText from './components/ScrollingText';
import ScrollingImage from './components/ScrollingImage';



function shuffleArray(array, seed) {
  let rng = seedrandom(seed);
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(rng() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function App() {
  const [images, setImages] = useState([]);
  const observerRef = useRef(null);

  useEffect(() => {
    function loadImages() {
      const context = require.context('./assets/');
      const imagePaths = context.keys();
      
      const loadedImages = imagePaths.map(path => context(path));
      const imageSources = loadedImages.map(module => module);
      return imageSources;
    }

    setImages(shuffleArray(loadImages(), 21));
  }, []);

  useEffect(() => {
    const options = {
      threshold: 0.5 // Adjust as needed
    };

    observerRef.current = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
          entry.target.classList.remove('animate-out');
        } else {
          // Check if the target is above the viewport
          const isScrollingUp = entry.boundingClientRect.y > 0;
          entry.target.classList.add(isScrollingUp ? 'animate-out' : 'animate-in');
          entry.target.classList.remove(isScrollingUp ? 'animate-in' : 'animate-out');
        }
      });
    }, options);

    const images = document.querySelectorAll('.image-element');
    images.forEach((image) => observerRef.current.observe(image));

    return () => {
      observerRef.current.disconnect();
    };
  }, [images]);
      

  return (
    <>
    <div className="app" >
      <div className="image-layer">
        {images.map((src, index) => (
          <ScrollingImage key={index} src={src} className="image-element" />
        ))}
      </div>

      <div className="content">
        <div className='title'>cine E sima?</div>
        <ScrollingText text="❌ un robot\n❌ un caine actor\n❌ io?\n❓un nebun\n✅sarbatoritul" />
        <ScrollingText text="dar intrebarea adevarata este CUM este sima?" />
        <ScrollingText text="sima este:\nun prieten bun\n" />
        <ScrollingText text="LA MULTI ANI, SIMA!\nCRACIUN FERICIT 2023" />
        <ScrollingText text="Stai, ce e asta???\nhttps://www.backerkit.com/c/projects/mcdm-productions/mcdm-rpg/pledges/313608#pledge_information"/>
      </div>
    </div>
  </>
  );
}

export default App;