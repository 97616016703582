import React, { useEffect, useRef, useState } from 'react';

const ScrollingText = ({ text: text, threshold = 0.1 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: threshold
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, [textRef, threshold]);

  const sentences = text.split('\\n');
  return (
    <div>
      <div className='spacer'/>
        <div ref={textRef} className={`scrolling-text ${isVisible ? 'visible' : ''}`}>
          {sentences.map((sentence, i) => (
            <h1 key={i}>{sentence}</h1>
          ))}
        </div>
      <div className='spacer'/>
    </div>
  );
};

export default ScrollingText;
