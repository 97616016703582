// ScrollingImage.js
import React from 'react';
import "../styles/index.css"

const ScrollingImage = ({ src, className }) => {
  const rotationAngle = Math.random() * 30 - 15; // Range -10 to 10
  const transformStyle = { '--rotate': `${rotationAngle}deg`};

  const directionClass = Math.random() > 0.5 ? 'move-left' : 'move-right';

  return (
    <div className={`${className} image-container ${directionClass}`}>
      <img src={src} alt="Scrolling" style={transformStyle} />
    </div>
  );
};

export default ScrollingImage;
